import React, { useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '400px',
};

const center = {
    lat: 30.900608,
    lng: 75.001357,
};

const MapLocation = (props: { machineData: any }) => {
    const { machineData = [] } = props;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    });

    const [map, setMap] = useState<any>(null);
    const [activeMarker, setActiveMarker] = useState<number | null>(null); // Track which marker is active

    const validMachineData = machineData.filter((location: any) =>
        location.lat && location.lng && !isNaN(location.lat) && !isNaN(location.lng)
    );


    const onLoad = useCallback((map: any) => {
        const validMachineData = machineData.filter((loc: any) =>
            loc.lat && loc.lng && !isNaN(loc.lat) && !isNaN(loc.lng)
        );

        if (validMachineData.length > 1) {
            const bounds = new window.google.maps.LatLngBounds();
            validMachineData.forEach((loc: any) => bounds.extend(loc));
            map.fitBounds(bounds, { top: 50, bottom: 50, left: 50, right: 50 });
        } else if (validMachineData.length === 1) {
            map.setCenter(validMachineData[0]);
            map.setZoom(10);
        } else {
            map.setCenter(center);
            map.setZoom(5);
        }
        setMap(map);
    }, [machineData]);


    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const handleMarkerClick = (index: number) => {
        setActiveMarker(index);
    };

    const handleInfoWindowClose = () => {
        setActiveMarker(null);
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={5}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {validMachineData.map((location: any, index: number) => (
                <Marker
                    key={index}
                    position={{ lat: location.lat, lng: location.lng }}
                    onClick={() => handleMarkerClick(index)}
                >
                    {activeMarker === index && (
                        <InfoWindow onCloseClick={handleInfoWindowClose}>
                            <div>
                                <h4>{`${location.machine_name}(${location.machine_code})` || "Location"}</h4>
                            </div>
                        </InfoWindow>
                    )}
                </Marker>
            ))}

        </GoogleMap>
    );
};

export default MapLocation;
