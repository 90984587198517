import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { BASE_URL, BASE_URL_TWO, decryptObject } from "../../../Constant/Constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserBalance from "./components/UserBalance";
import TransactionHistory from "./components/TransactionHistory";
import axios from 'axios';

const tabs = [
  { name: "Pay with phone", current: true, state: "phone" },
  { name: "Pay with card", current: false, state: "card" },
  { name: "Transactions", current: false, state: "transaction" },
];

type phoneInputs = {
  phoneNumber: string;
  amount: number;
  rfidNumber: string;
  cardAmount: number;
  phoneDescription: string;
  cardDescription: string
};

const Topup = () => {
  const [tab, setTab] = useState("phone");
  const [phoneTopupLoader, setPhoneTopupLodaer] = useState(false);
  const [rfidTopupLoader, setRfidTopupLodaer] = useState(false);
  const [showUserBalance, setShowUserBalance] = useState(false);
  const [showCardBalance, setShowCardBalance] = useState(false);

  //get User data
  const [getUsersData, setGetUsersData] = useState([]);

  //localStorage
  const authData = localStorage.getItem("auth");
  // const localStorageData = JSON.parse(authData);
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<phoneInputs>();

  const handlePhoneSubmit: SubmitHandler<phoneInputs> = (data) => {
    setPhoneTopupLodaer(true);
    try {
      axios.post(`${BASE_URL_TWO}webTopupBalance/phone`, {
        phoneNumber: data.phoneNumber,
        amount: data.amount,
        description: data.phoneDescription,
        user_email: localStorageData.user.email
      }).then((res: any) => {
        setPhoneTopupLodaer(false);
        getUsersList();
        toast.success("Topup to the user successfully done!");
      }).catch((err: any) => {
        setPhoneTopupLodaer(false);
        toast.error(err.message);
      })
    } catch (error) {
      console.error(error);
    }
  };

  const handleCardSubmit: SubmitHandler<phoneInputs> = (data: any) => {
    setRfidTopupLodaer(true);
    try {
      axios.post(`${BASE_URL_TWO}webTopupBalance/cardNumber`, {
        cardNumber: data.rfidNumber,
        amount: data.cardAmount,
        description: data.cardDescription,
        user_email: localStorageData.user.email
      }).then((res: any) => {
        setRfidTopupLodaer(false);
        getUsersList();
        toast.success("Topup to the user successfully done!");
      }).catch((err: any) => {
        setRfidTopupLodaer(false);
        toast.error(err.message);
      })
    } catch (error) {
      console.error(error);
    }
  };

  const handleResetPhone = () => {
    if (watchAllFields && watchAllFields.phoneNumber && watchAllFields.phoneNumber.length === 11) {
      try {
        axios.post(`${BASE_URL_TWO}topupReset/phone`, {
          phoneNumber: watchAllFields.phoneNumber
        }).then((res: any) => {
          setRfidTopupLodaer(false);
          getUsersList();
          toast.success(res.data.message);
        }).catch((err: any) => {
          setRfidTopupLodaer(false);
          toast.error(err.message);
        })
      } catch (error) {
        console.error(error);
      }
    }
  }

  const handleResetCard = () => {
    if (watchAllFields && watchAllFields.rfidNumber) {
      try {
        axios.post(`${BASE_URL_TWO}topupReset/card`, {
          cardNumber: watchAllFields.rfidNumber
        }).then((res: any) => {
          setRfidTopupLodaer(false);
          getUsersList();
          toast.success(res.data.message);
        }).catch((err: any) => {
          setRfidTopupLodaer(false);
          toast.error(err.message);
        })
      } catch (error) {
        console.error(error);
      }
    }
  }

  const watchAllFields = watch();

  useEffect(() => {
    if (
      watchAllFields &&
      watchAllFields.phoneNumber &&
      watchAllFields.phoneNumber.length === 11
    ) {
      setShowUserBalance(true);
    } else {
      setShowUserBalance(false);
    }
  }, [watch, watchAllFields]);

  useEffect(() => {
    if (
      watchAllFields &&
      watchAllFields.rfidNumber &&
      watchAllFields.rfidNumber.length > 3
    ) {
      setShowCardBalance(true);
    } else {
      setShowCardBalance(false);
    }
  }, [watch, watchAllFields]);

  const getUsersList = async () => {
    try {
      const result = await axios.get(`${BASE_URL_TWO}api/topup/getUser`)
      setGetUsersData(result.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUsersList();
  }, []);

  const userPhoneBalance = getUsersData.find(
    (e: any) => e.mobile_number === watchAllFields.phoneNumber
  );

  const userRfidBalance = getUsersData.find(
    (e: any) => e.card_number === watchAllFields.rfidNumber
  );


  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <div
              className={`${tab === "phone"
                ? "cursor-pointer whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600 dark:!text-white"
                : "cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium dark:!text-white"
                }`}
              onClick={() => setTab("phone")}
            >
              Pay with phone
            </div>
            <div
              className={`${tab === "card"
                ? "cursor-pointer whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600 dark:!text-white"
                : "cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium dark:!text-white"
                }`}
              onClick={() => setTab("card")}
            >
              Pay with card{" "}
            </div>
            <div
              className={`${tab === "transaction"
                ? "cursor-pointer whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600 dark:!text-white"
                : "cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium dark:!text-white"
                }`}
              onClick={() => setTab("transaction")}
            >
              Topup History{" "}
            </div>
          </nav>
        </div>
      </div>

      {tab === "phone" ? (
        <>
          <form onSubmit={handleSubmit(handlePhoneSubmit)} className="sm-max:grid justify-center">
            <div className="flex items-end justify-start">
              <div className="mt-10 w-1/2 sm-max:w-full">
                <label className="block text-sm font-medium leading-6 text-gray-900 dark:!text-white">
                  Phone
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="phoneNumber"
                    id="phoneNumber"
                    className="block w-full rounded-md dark:!bg-gray-900 dark:!text-white border-0 py-1.5 pl-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                    placeholder="03*********"
                    {...register("phoneNumber", {
                      required: true,
                      pattern: /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
                    })}
                  />
                </div>

                {errors.phoneNumber && (
                  <span className="text-red-500">This field is required</span>
                )}
              </div>
              <div className="pl-5">
                {showUserBalance ? (
                  <div>
                    {userPhoneBalance === undefined ? (
                      <h1>No user found against the phone number</h1>
                    ) : (
                      <UserBalance
                        balance={
                          userPhoneBalance === undefined
                            ? null
                            : userPhoneBalance.balance
                        }
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="mt-5 w-1/2 sm-max:w-full">
              <label className="block text-sm font-medium leading-6 text-gray-900 dark:!text-white">
                Amount
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  className="block w-full rounded-md text-sm dark:!bg-gray-900 dark:!text-white border-0 py-1.5 pl-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                  placeholder="Enter amount b/w 1 to 30,000"
                  {...register("amount", {
                    required: true,
                    pattern: {
                      value: /^(?:30000|[12]?[0-9]{1,4}|[1-9][0-9]{0,3}|0)$/,
                      message: 'Please enter a number from 0 to 30000',
                    },
                  })}
                />
              </div>
              {errors.amount && (
                <span className="text-red-500">Please enter a number from 0 to 30000</span>
              )}
            </div>

            <div className="mt-5 w-1/2 sm-max:w-full">
              <label className="block text-sm font-medium leading-6 text-gray-900 dark:!text-white">
                Description
              </label>
              <div className="mt-2">
                <textarea
                  name="phoneDescription"
                  id="phoneDescription"
                  className="block w-full rounded-md text-sm dark:!bg-gray-900 dark:!text-white border-0 py-1.5 pl-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                  placeholder="Purpose of payment"
                  {...register("phoneDescription", {
                    required: true
                  })}
                ></textarea>
              </div>
              {errors.amount && (
                <span className="text-red-500">Please enter purpose of payment</span>
              )}
            </div>

            {phoneTopupLoader ? (
              <button className="mt-5 inline-flex w-1/2 cursor-pointer items-center gap-x-2 rounded-md bg-teal-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Loading...
              </button>
            ) : (
              <div className="w-1/2 flex justify-between">
                <input
                  type="submit"
                  className="mt-5 inline-flex w-12/12	mr-3 cursor-pointer items-center gap-x-2 rounded-md bg-teal-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  value="Topup with phone"
                  name="toupwithphone"
                />
              </div>
            )}
          </form>
          <button onClick={handleResetPhone} className="mt-5 inline-flex w-12/12	 cursor-pointer items-right gap-x-2 rounded-md bg-white border border-teal-500 px-3.5 py-2.5 text-center text-sm font-semibold text-teal-500 shadow-sm hover:bg-teal-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Reset to zero
          </button>
        </>
      ) : tab === "card" ? (
        <div>
          <form onSubmit={handleSubmit(handleCardSubmit)}>
            <div className="flex items-end justify-start">
              <div className="mt-10 w-1/2">
                <label className="block text-sm font-medium leading-6 text-gray-900 dark:!text-white">
                  Card Number
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="rfidNumber"
                    className="block w-full rounded-md dark:!bg-gray-900 dark:!text-white border-0 py-1.5 pl-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                    placeholder="5123"
                    {...register("rfidNumber", {
                      required: true,
                    })}
                  />
                </div>
              </div>
              <div className="pl-5">
                {showCardBalance ? (
                  <div>
                    {userRfidBalance === undefined ? (
                      <h1 className="text-red-500">
                        No user found against the card number
                      </h1>
                    ) : (
                      <>
                        <UserBalance
                          balance={
                            userRfidBalance === undefined
                              ? null
                              : userRfidBalance.balance
                          }
                        />
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mt-5 w-1/2">
              <label className="block text-sm font-medium leading-6 text-gray-900 dark:!text-white">
                Amount
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="cardAmount"
                  className="block w-full rounded-md dark:!bg-gray-900 dark:!text-white border-0 py-1.5 pl-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                  placeholder="Enter amount b/w 1 to 30,000"
                  {...register("cardAmount", {
                    required: true,
                    pattern: {
                      value: /^(?:30000|[12]?[0-9]{1,4}|[1-9][0-9]{0,3}|0)$/,
                      message: 'Please enter a number from 0 to 30000',
                    },
                  })}
                />
              </div>
            </div>

            <div className="mt-5 w-1/2 sm-max:w-full">
              <label className="block text-sm font-medium leading-6 text-gray-900 dark:!text-white">
                Description
              </label>
              <div className="mt-2">
                <textarea
                  name="cardDescription"
                  id="cardDescription"
                  className="block w-full rounded-md text-sm dark:!bg-gray-900 dark:!text-white border-0 py-1.5 pl-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                  placeholder="Purpose of payment"
                  {...register("cardDescription", {
                    required: true
                  })}
                ></textarea>
              </div>
              {errors.amount && (
                <span className="text-red-500">Please enter purpose of payment</span>
              )}
            </div>


            {rfidTopupLoader ? (
              <button className="mt-5 inline-flex w-1/2 cursor-pointer items-center gap-x-2 rounded-md bg-teal-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                className="mt-5 inline-flex w-12/12	mr-3 cursor-pointer items-center gap-x-2 rounded-md bg-teal-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                value="Topup with card"
                name="toupwithcard"
              />
            )}
          </form>

          <button onClick={handleResetCard} className="mt-5 inline-flex w-12/12	 cursor-pointer items-right gap-x-2 rounded-md bg-white border border-teal-500 px-3.5 py-2.5 text-center text-sm font-semibold text-teal-500 shadow-sm hover:bg-teal-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Reset to zero
          </button>
        </div>
      ) : (
        <TransactionHistory />
      )}
    </div>
  );
};

export default Topup;
