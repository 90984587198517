import axios from 'axios';
import Card from 'components/card';
import SkeletonLoader from 'components/Loader/SkeletonLoader';
import { addCommasToNumber, BASE_URL_TWO } from 'Constant/Constant';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ButterflyLineChart = () => {
    const [loader, setLoader] = useState(true);
    const [pieState, setPieState] = useState("transaction");
    const [butterflyTransactionCounts, setButterflyTransactionCounts] = useState([]);
    const [butterflyAmount, setButterflyAmount] = useState([]);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [totalBrands, setTotalBrands] = useState(0);
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({
        series: [{
            data: [{
                x: 'category A',
                y: 10
            }, {
                x: 'category B',

                y: 18
            }, {
                x: 'category C',
                y: 13
            }]
        }],
        options: {
            chart: {
                type: 'bar' as 'bar',
                height: 350,
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    borderRadiusApplication: 'end',
                    horizontal: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            yaxis: {
                categories: [],
            },
        },
    });

    const pieChart = () => {
        axios.post(`${BASE_URL_TWO}PieMainDashboardButterfly`)
            .then((res) => {
                setButterflyTransactionCounts(res.data.butterflyTransactionCounts);
                setButterflyAmount(res.data.butterflyAmount);
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    labels: res.data.butterflyBrandsId,
                }));

                setTotalTransactions(res.data.totalTransactions);
                setTotalRevenue(res.data.totalRevenue);
                setTotalBrands(res.data.butterflyBrandsId.length);
                setLoader(false);
            })
            .catch((err) => {
                console.error(err);
            });

    };
  
    useEffect(() => {
        pieChart();
    }, []);

    useEffect(() => {
        if (pieState === "transaction") {
            setSeries([
                {
                    data: butterflyTransactionCounts, // Ensure this is an array of numbers
                },
            ]);
        } else if (pieState === "revenue") {
            setSeries([
                {
                    data: butterflyAmount, // Ensure this is an array of numbers
                },
            ]);
        }
    }, [pieState, butterflyTransactionCounts, butterflyAmount]);

    return (
        <>
            {loader ? (
                <SkeletonLoader />
            ) : (
                <Card extra="flex flex-col bg-white w-full rounded-3xl p-5 text-center">
                    <div className="flex items-center justify-start">
                        <select
                            className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white"
                            value={pieState}
                            onChange={(e) => setPieState(e.target.value)}
                        >
                            <option value="transaction">Transaction</option>
                            <option value="revenue">Revenue</option>
                        </select>
                    </div>

                    <div>
                        <div id="chart">
                            <ReactApexChart options={options} series={series} type="bar" />
                        </div>
                        <div id="html-dist"></div>
                    </div>

                    {/* <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                        <div className="flex flex-col items-center justify-center">
                            <div className="flex items-center justify-center">
                                <div className="h-2 w-2 rounded-full bg-brand-500" />
                                <p className="ml-1 text-sm font-normal text-gray-600">
                                    Total Revenue
                                </p>
                            </div>
                            <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                                {addCommasToNumber(totalRevenue)}
                            </p>
                        </div>

                        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

                        <div className="flex flex-col items-center justify-center">
                            <div className="flex items-center justify-center">
                                <div className="h-2 w-2 rounded-full bg-[#6AD2FF]" />
                                <p className="ml-1 text-sm font-normal text-gray-600">
                                    Total Transaction
                                </p>
                            </div>
                            <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                                {addCommasToNumber(totalTransactions)}
                            </p>
                        </div>

                        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

                        <div className="flex flex-col items-center justify-center">
                            <div className="flex items-center justify-center">
                                <div className="h-2 w-2 rounded-full bg-[#6AD2FF]" />
                                <p className="ml-1 text-sm font-normal text-gray-600">
                                    Total Brands
                                </p>
                            </div>
                            <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
                                {addCommasToNumber(totalBrands)}
                            </p>
                        </div>
                    </div> */}
                </Card>
            )}
        </>
    );
}

export default ButterflyLineChart