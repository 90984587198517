import { locationsTable } from "Constant/Constant";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

const LocationsTable = (props: { tableData: any }) => {

  const { tableData } = props;

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:!text-gray-500">
            Locations
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the locations in your account including their name,
            company code, location and action.
          </p>
        </div>
        <div>
          <CSVLink data={tableData} headers={locationsTable} className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
            Export as csv
          </CSVLink>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 dark:!bg-navy-800">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 text-gray-900 dark:!text-gray-500"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 text-gray-900 dark:!text-gray-500"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 text-gray-900 dark:!text-gray-500"
                    >
                      Company code
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 text-gray-900 dark:!text-gray-500"
                    >
                      Location
                    </th>
                    {/* <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 text-gray-900 dark:!text-gray-500"
                    >
                      Action
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {tableData.map((loc: any, key: number) => (
                    <tr key={loc._id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-gray-900 dark:!text-gray-500">
                        {key + 1}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-gray-900 dark:!text-gray-500">
                        {loc.machine_name}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-gray-900 dark:!text-gray-500">
                        {loc.machine_code}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-gray-900 dark:!text-gray-500">
                        <a
                          href={`${loc.machine_location}`}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-500 underline"
                        >
                          {loc.machine_location}
                        </a>
                      </td>
                      {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-gray-900 dark:!text-gray-500 ">
                        <Link to={`/admin/location-details/${loc.machine_code}`}>
                          <button className="block rounded-md bg-teal-600 px-5 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            Visit
                          </button>
                        </Link>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationsTable;
