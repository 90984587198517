import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { BASE_URL_TWO } from "Constant/Constant";
import { Switch } from '@headlessui/react'

type Inputs = {
    machine_code: string;
    machine_name: number;
    machine_location: number;
    machine_type: number;
    lat: number;
    lng: number;
};

export default function AddMachine({ open, setOpen }: { open: boolean; setOpen: any }) {
    const cancelButtonRef = useRef(null);

    const [enabled, setEnabled] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<Inputs>();

    const onSubmit = async (data: any) => {
        const bodyData = {
            machine_code: data.machine_code,
            machine_name: data.machine_name,
            machine_location: data.machine_location,
            machine_type: data.machine_type,
            created_at: Date.now(),
            is_active: enabled ? 1 : 0,
            lat: data.lat,
            lng: data.lng
        }

        try {
            const response = await fetch(`${BASE_URL_TWO}api/machines`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(bodyData),
            });

            if (response.ok) {
                alert("Machine added successfully!");
                reset();
                setOpen(false);
            } else {
                const errorData = await response.json();
                alert(`Error: ${errorData.message}`);
            }
        } catch (error) {
            console.error("API Error:", error);
            alert("Failed to insert machine.");
        }
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Machine Code</label>
                                        <input
                                            type="text"
                                            {...register("machine_code", { required: "Machine Code is required." })}
                                            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-teal-600 sm:text-sm/6"
                                            placeholder="Enter Machine Code"
                                        />
                                        {errors.machine_code && (
                                            <span className="text-red-500 text-sm">{errors.machine_code.message}</span>
                                        )}
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Machine Name</label>
                                        <input
                                            type="text"
                                            {...register("machine_name", { required: "Machine Name is required." })}
                                            className="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-teal-600 sm:text-sm/6"
                                            placeholder="Enter Machine Name"
                                        />
                                        {errors.machine_name && (
                                            <span className="text-red-500 text-sm">{errors.machine_name.message}</span>
                                        )}
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Location</label>
                                        <input
                                            type="text"
                                            {...register("machine_location", { required: "Location is required." })}
                                            className="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-teal-600 sm:text-sm/6"
                                            placeholder="Enter Location"
                                        />
                                        {errors.machine_location && (
                                            <span className="text-red-500 text-sm">{errors.machine_location.message}</span>
                                        )}
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                            Machine Type
                                        </label>
                                        <select
                                            {...register("machine_type", { required: "Machine Type is required." })}
                                            className="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-teal-600 sm:text-sm"
                                            defaultValue=""
                                        >
                                            <option value="" disabled>
                                                Select Machine Type
                                            </option>
                                            <option value="product">Product</option>
                                            <option value="liquid">Liquid</option>
                                        </select>
                                        {errors.machine_type && (
                                            <span className="text-red-500 text-sm">{errors.machine_type.message}</span>
                                        )}
                                    </div>


                                    <div className="flex justify-between">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Latitude</label>
                                            <input
                                                type="number"
                                                step="any"
                                                {...register("lat", { required: "Latitude is required." })}
                                                className="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-teal-600 sm:text-sm/6"
                                                placeholder="Enter Latitude"
                                            />
                                            {errors.lat && <span className="text-red-500 text-sm">{errors.lat.message}</span>}
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Longitude</label>
                                            <input
                                                type="number"
                                                step="any"
                                                {...register("lng", { required: "Longitude is required." })}
                                                className="block w-full rounded-md bg-white px-3 py-1 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-teal-600 sm:text-sm/6"
                                                placeholder="Enter Longitude"
                                            />
                                            {errors.lng && <span className="text-red-500 text-sm">{errors.lng.message}</span>}
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Active</label>
                                            <Switch
                                                checked={enabled}
                                                onChange={setEnabled}
                                                className={`${enabled ? 'bg-teal-600' : 'bg-gray-200'
                                                    } relative inline-flex h-5 w-10 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
                                            >
                                                <span className="sr-only">Enable setting</span>
                                                <span
                                                    className={`${enabled ? 'translate-x-5' : 'translate-x-0'
                                                        } inline-block h-4 w-4 transform rounded-full bg-white shadow transition-transform duration-200 ease-in-out`}
                                                />
                                            </Switch>
                                        </div>
                                    </div>

                                    <div className="flex justify-end space-x-4">
                                        <button
                                            type="button"
                                            className="rounded-md bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="rounded-md bg-teal-500 px-4 py-2 text-sm font-medium text-white hover:bg-teal-600"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
