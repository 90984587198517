import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const options = [
    { value: '3113', label: 'National foods Ground floor' },
    { value: '3114', label: 'National foods 3rd floor' },
    { value: '3115', label: 'National foods 1st floor' },
    { value: '3116', label: 'National foods 2nd floor' },
];

const animatedComponents = makeAnimated();

export default function AnimatedMulti({ selectedOptions, setSelectedOptions }: { setSelectedOptions: any; selectedOptions: any }) {

    const handleChange = (selected: any) => {
        setSelectedOptions(selected);
    };

    return (
        <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={options}
            placeholder="Filter users by machine"
            onChange={handleChange}
            value={selectedOptions}
        />
    );
}
