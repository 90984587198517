import { useState } from "react";
import { BiShow, BiHide } from "react-icons/bi";
import { MdDelete, MdModeEdit } from "react-icons/md";

import { allMachinesLists, calculateTime, capitalizeFirstLetter } from "Constant/Constant";
import { CreateRoleLists, CreateRolesMachines, RoleListProps } from "../CreateRolesInterfaces";
import DeleteModal from "./DeleteModal";
import UpdateModal from "./UpdateModal";

export default function RolesList(props: RoleListProps) {

    const { users, fetchApiData } = props;

    const [loading, setLoading] = useState(false);
    const [showPasswords, setShowPasswords] = useState(users.map(() => false));
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState(null);

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateUserId, setUpdateUserId] = useState(null);

    const filterMachineNames = (machines: CreateRolesMachines[]) => {
        return machines.map((machine: CreateRolesMachines) => {
            const temp = allMachinesLists.find((machineList) => machineList.machineCode === machine.machine_code);
            return temp.label || ""
        });
    };

    return (
        <div className="mt-3 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300 px-5">
                            <thead className="dark:!bg-gray-900">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 dark:!text-white sm:pl-6">
                                        Name
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 dark:!text-white sm:pl-6">
                                        Email
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 dark:!text-white sm:pl-6">
                                        Password
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 dark:!text-white sm:pl-6">
                                        Created at
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 dark:!text-white sm:pl-6">
                                        Roles
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 dark:!text-white sm:pl-6">
                                        Machines
                                    </th>

                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-center text-gray-900 dark:!text-white sm:pl-6">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 p-5">
                                {users && users.map((person: CreateRoleLists, i: number) => (
                                    <tr key={person.email}>
                                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                                            <div className="flex items-center ml-3">
                                                <div className="h-11 w-11 flex-shrink-0">
                                                    <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-teal-600 text-white">
                                                        <span className="font-medium leading-none text-white">
                                                            {person.first_name[0] + "" + person.last_name[0]}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="ml-4">
                                                    <div className="font-medium text-gray-900 dark:!text-gray-500">{person.first_name + " " + person.last_name}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                                            <div className="text-gray-900 dark:!text-gray-500">{person.email} </div>
                                        </td>
                                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                                            <div className="text-gray-900 flex items-center dark:!text-gray-500">
                                                {showPasswords[i] ? (
                                                    <span>{person.password}</span>
                                                ) : (
                                                    <span>********</span>
                                                )}
                                                {showPasswords[i] ? (
                                                    <BiHide
                                                        className="ml-3 cursor-pointer"
                                                        onClick={() => {
                                                            const newShowPasswords = [...showPasswords];
                                                            newShowPasswords[i] = !newShowPasswords[i];
                                                            setShowPasswords(newShowPasswords);
                                                        }}
                                                    />
                                                ) : (
                                                    <BiShow
                                                        className="ml-3 cursor-pointer"
                                                        onClick={() => {
                                                            const newShowPasswords = [...showPasswords];
                                                            newShowPasswords[i] = !newShowPasswords[i];
                                                            setShowPasswords(newShowPasswords);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </td>
                                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">{calculateTime(person.created_at)}</td>
                                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                                            <span className={`inline-flex items-center rounded-md ${person.user_role === "super admin" ? "bg-green-50" : person.user_role === "company" ? "bg-yellow-50" : "bg-purple-50"}  px-2 py-1 text-xs font-medium ${person.user_role === "super admin" ? "text-green-700" : person.user_role === "company" ? "text-yellow-700" : "text-purple-700"}  ring-1 ring-inset ${person.user_role === "super admin" ? "ring-green-600/20" : "ring-yellow-600/20"}`}>
                                                {capitalizeFirstLetter(person.user_role)}
                                            </span>
                                        </td>
                                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                                            <p>
                                                {filterMachineNames(person.machines).map((name, index) => (
                                                    <p key={index} className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-gray-900 bg-gray-50 ring-gray-500/10 my-2">
                                                        {name}
                                                        {index !== filterMachineNames(person.machines).length - 1 && <br />}
                                                    </p>
                                                ))}
                                            </p>
                                        </td>

                                        <td className="text-gray-900 sm:pl-0 dark:!text-gray-500 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium flex justify-center items-center">
                                            <div>
                                                {
                                                    person.user_role === "super admin" ? null :
                                                        <MdDelete onClick={() => { setDeleteModalOpen(!deleteModalOpen); setDeleteUserId(person.id) }} className="cursor-pointer h-6 w-6" />

                                                }
                                            </div>
                                            <div className="ml-2">
                                                {
                                                    person.user_role === "super admin" ? null :
                                                        <MdModeEdit className="cursor-pointer h-6 w-6"
                                                            onClick={() => { setUpdateModalOpen(!updateModalOpen); setUpdateUserId(person) }}
                                                        />
                                                }
                                            </div>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <DeleteModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                setLoading={setLoading}
                fetchApiData={fetchApiData}
                deleteUserId={deleteUserId}
                loading={loading}
            />
            {
                updateUserId ?
                    <UpdateModal
                        open={updateModalOpen}
                        setOpen={setUpdateModalOpen}
                        updateUser={updateUserId}
                        fetchApiData={fetchApiData}
                    /> : null
            }

        </div>
    )
}