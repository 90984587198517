import moment from "moment";

export const BASE_URL = "https://davaam-life.herokuapp.com/";

export const BASE_URL_TWO =
  "https://davaam-backend-nodejs-4199d6d4d449.herokuapp.com/";

export const BASE_URL_THREE = "https://davaambackend.up.railway.app/";

export const LOCAL_BASE_URL = "http://localhost:3009/"

export const barChartCustomData = {
  weekly: {
    revenue: <any>[],
    transaction: <any>[],
  },
  monthly: {
    revenue: <any>[],
    transaction: <any>[],
  },
};

export function formatDate(date: any) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export const MACHINE = "MACHINE";
export const STOCK_LEVELS = "STOCK_LEVELS";
export const SALES_AND_USAGE = "SALES_AND_USAGE";
export const STOCK_MANAGMENT = "STOCK_MANAGMENT";
export const USER_TRANSACTIONS = "USER_TRANSACTIONS";

export const addCommasToNumber = (num: string | number) => {
  if (num) {
    let numArr = num.toString().split("");
    if (numArr.length === 4) {
      numArr.splice(1, 0, ",");
    } else if (numArr.length === 5) {
      numArr.splice(2, 0, ",");
    } else if (numArr.length === 6) {
      numArr.splice(3, 0, ",");
    } else if (numArr.length === 7) {
      numArr.splice(2, 0, ",");
      numArr.splice(5, 0, ",");
    } else if (numArr.length === 8) {
      numArr.splice(3, 0, ",");
      numArr.splice(6, 0, ",");
    } else if (numArr.length === 9) {
      numArr.splice(2, 0, ",");
      numArr.splice(5, 0, ",");
      numArr.splice(8, 0, ",");
    }
    return numArr.join("").toString();
  }
  return "";
};

export const locationsTableData = [
  { name: "SNO" },
  { name: "Name" },
  { name: "Company code" },
  { name: "Locations" },
  { name: "Action" },
];

export const machineTableData = [
  { name: "SNO" },
  { name: "Machine id" },
  { name: "Locations" },
  { name: "Type" },
  { name: "Brands" },
  { name: "Action" },
];

export const rolesTableData = [
  { name: "Name" },
  { name: "Email" },
  { name: "Password" },
  { name: "Created at" },
  { name: "Roles" },
];

export const productTableData = [
  { name: "Machine" },
  { name: "Location" },
  { name: "Product price" },
  { name: "Edit" },
];

export const stockManagment = [
  { name: "#" },
  { name: "Brand name" },
  { name: "Price" },
  { name: "Brand id " },
  { name: "Bar code" },
];

export const userStaticsHeader = [
  { label: "Name", key: "name" },
  { label: "User Since", key: "created_at" },
  { label: "Phone Number", key: "mobile_number" },
  { label: "Amount", key: "balance" },
];

export const machineUserTransactions = [
  { label: "Phone", key: "msisdn" },
  { label: "Product", key: "brand_name" },
  { label: "Amount", key: "amount" },
  { label: "Price", key: "price" },
  { label: "Quantity(ml)", key: "quantity" },
  { label: "Created at", key: "created_at" },
];

export const dashboardMachineStatics = [
  { label: "Phone", key: "msisdn" },
  { label: "Product", key: "brand_name" },
  { label: "User Since", key: "created_at_sql" },
  { label: "Amount", key: "amount" },
  { label: "Quantity", key: "quantity" },
];

export const allMachines = [
  { label: "Machine Id", key: "_id" },
  { label: "Locations", key: "location" },
  { label: "Type", key: "type" },
  { label: "Brands", key: "brands" },
];

export const machineStockManagment = [
  { label: "Brand name", key: "name" },
  { label: "Price", key: "price" },
  { label: "Brand id ", key: "brand_id" },
  { label: "Bar code", key: "bar_code" },
];

export const locationsTable = [
  { label: "Name", key: "name" },
  { label: "Company code", key: "company_code" },
  { label: "Location ", key: "location" },
];

export const productDetailsTable = [
  { label: "Machine", key: "machine_id" },
  { label: "Location", key: "location" },
  { label: "Product Price ", key: "price" },
];

export const feedbackList = [
  { label: "Email", key: "email" },
  { label: "Description", key: "description" },
  { label: "Date", key: "created_at" },
];

export const ibexCorporateClients = [
  { label: "Card number", key: "card_number" },
  { label: "Balance", key: "balance" },
  { label: "Created at", key: "created_at_sql" },
  { label: "Phone", key: "mobile_number" },
];

export const usersListHeader = [
  { label: "Name", key: "name" },
  { label: "Phone number", key: "mobile_number" },
  { label: "Balance", key: "balance" },
  { label: "Card number", key: "card_number" },
  { label: "Created at", key: "created_at_sql" },
];

export const pieCsvHead = [
  { label: "Revenue", key: "revenueProduct" },
  { label: "PKR", key: "revenueAmount" },
  { label: "Volume", key: "volumeProduct" },
  { label: "ml", key: "volumeAmount" },
  { label: "Transaction", key: "transactionProduct" },
  { label: "", key: "transactionAmount" },
];

export const barChartHead = [
  { label: "Weekly revenue", key: "[0].weeks.revenueWeekly" },
  { label: "", key: "[0].weeks.revenueWeeklyNumbers" },
  { label: "Monthly revenue", key: "[0].months.revenueMonthly" },
  { label: "", key: "[0].months.revnueMonthlyNumbers" },
  { label: "Monthly Transactions", key: "[0].months.transactionMonthly" },
  { label: "", key: "[0].months.transactionMonthlyNumber" },
  { label: "Weekly Transactions", key: "[0].weeks.transactionWeekly" },
  { label: "", key: "[0].weeks.transactionWeeklyNumbers" },
];

export function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1) || "";
}

export const DONT_ACCESS = "You don't have access";

export const allMachinesLists = [
  {
    machineCode: "0002",
    machineName: "Unilever Innovation Lab",
    type: "liquid",
    value: "0002",
    label: "Unilever Innovation Lab (0002)",
  },
  {
    machineCode: "3002",
    machineName: "Emaar",
    type: "product",
    value: "3002",
    label: "Emaar (3002)",
  },
  {
    machineCode: "3101",
    machineName: "Tapal IBA Aman CED",
    type: "product",
    value: "3101",
    label: "Tapal IBA Aman CED (3101)",
  },
  {
    machineCode: "3102",
    machineName: "IVS 3rd floor",
    type: "product",
    value: "3102",
    label: "IVS 3rd floor (3102)",
  },

  {
    machineCode: "3103",
    machineName: "IVS 3nd floor",
    type: "product",
    value: "3103",
    label: "IVS 3nd floor (3103)",
  },
  {
    machineCode: "3104",
    machineName: "IVS 2nd floor	",
    type: "product",
    value: "3104",
    label: "IVS 2nd floor	(3104)",
  },
  {
    machineCode: "3105",
    machineName: "KE HO	",
    type: "product",
    value: "3105",
    label: "KE HO	(3105)",
  },
  {
    machineCode: "3125",
    machineName: "JP coats",
    type: "product",
    value: "3125",
    label: "JP coats (3125)",
  },
  {
    machineCode: "3126",
    machineName: "JP coats Lahore",
    type: "product",
    value: "3126",
    label: "JP coats Lahore(3126)",
  },
  {
    machineCode: "3127",
    machineName: "LUMS SuperStore washroom",
    type: "product",
    value: "3127",
    label: "LUMS SuperStore washroom (3127)",
  },
  {
    machineCode: "3128",
    machineName: "LUMS F3 hostel",
    type: "product",
    value: "3128",
    label: "LUMS F3 hostel (3128)",
  },
  {
    machineCode: "3129",
    machineName: "Legends Arena	",
    type: "product",
    value: "3129",
    label: "Legends Arena (3129)",
  },

  {
    machineCode: "3106",
    machineName: "Iba faysal block",
    type: "product",
    value: "3106",
    label: "Iba faysal block (3106)",
  },
  {
    machineCode: "3107",
    machineName: "KE Elander - Healthcare",
    type: "product",
    value: "3107",
    label: "KE Elander - Healthcare	(3107)",
  },
  {
    machineCode: "3112",
    machineName: "Dolmen Mall",
    type: "product",
    value: "3112",
    label: "Dolmen Mall (3112)",
  },
  {
    machineCode: "3113",
    machineName: "National foods Ground floor 3113",
    type: "product",
    value: "3113",
    label: "National foods Ground floor(3113)",
  },
  {
    machineCode: "3114",
    machineName: "National foods 3rd floor 3114",
    type: "product",
    value: "3114",
    label: "National foods 3rd floor(3114)",
  },
  {
    machineCode: "3115",
    machineName: "National foods 1st floor 3115",
    type: "product",
    value: "3115",
    label: "National foods 1st floor(3115)",
  },
  {
    machineCode: "3116",
    machineName: "National foods 2nd floor 3116",
    type: "product",
    value: "3116",
    label: "National foods 2nd floor(3116)",
  },
  {
    machineCode: "3117",
    machineName: "National foods Ittehad",
    type: "product",
    value: "3117",
    label: "National foods Ittehad(3117)",
  },
  {
    machineCode: "3118",
    machineName: "National foods Port Qasim",
    type: "product",
    value: "3118",
    label: "National foods Port Qasim(3118)",
  },
  {
    machineCode: "3119",
    machineName: "National foods faisalabad	",
    type: "product",
    value: "3119",
    label: "National foods faisalabad	(3119)",
  },
  {
    machineCode: "3109",
    machineName: "KE Civic centre",
    type: "product",
    value: "3109",
    label: "KE Civic centre (3109)",
  },
  {
    machineCode: "3110",
    machineName: "IBEX",
    type: "product",
    value: "3110",
    label: "IBEX (3110)",
  },
  {
    machineCode: "3111",
    machineName: "Emaar",
    type: "product",
    value: "3111",
    label: "Emaar (3111)",
  },
  {
    machineCode: "3201",
    machineName: "IBA City Campus",
    type: "product",
    value: "3201",
    label: "IBA City Campus (3201)",
  },
  {
    machineCode: "3202",
    machineName: "AKU Full Pack",
    type: "product",
    value: "3202",
    label: "AKU Full Pack (3202)",
  },
  {
    machineCode: "3203",
    machineName: "IBA Girls Hostel",
    type: "product",
    value: "3203",
    label: "IBA Girls Hostel (3203)",
  },
  {
    machineCode: "4101",
    machineName: "Mian noor",
    type: "liquid",
    value: "4101",
    label: "Mian noor (4101)",
  },
  {
    machineCode: "4102",
    machineName: "PCSIR",
    type: "liquid",
    value: "4102",
    label: "PCSIR (4102)",
  },
  {
    machineCode: "2101",
    machineName: "Davaam Office",
    type: "liquid",
    value: "2101",
    label: "Davaam Office (2101)",
  },
  {
    machineCode: "2201",
    machineName: "Davaam Office",
    type: "liquid",
    value: "4101",
    label: "Davaam Office (4101)",
  },
  {
    machineCode: "4001",
    machineName: "Davaam Office",
    type: "liquid",
    value: "4001",
    label: "Davaam Office (4001)",
  },
  {
    machineCode: "4002",
    machineName: "Davaam Office",
    type: "liquid",
    value: "4002",
    label: "Davaam Office (4002)",
  },
  {
    machineCode: "4103",
    machineName: "Akthar",
    type: "liquid",
    value: "4103",
    label: "Mian noor (4103)",
  },
  {
    machineCode: "3121",
    machineName: "Merit Packaging",
    type: "product",
    value: "3121",
    label: "Merit Packaging (3121)",
  },

  {
    machineCode: "3135",
    machineName: "EBM Karachi	",
    type: "product",
    value: "3135",
    label: "EBM Karachi (3135)",
  },
  {
    machineCode: "3136",
    machineName: "EBM Karachi	2",
    type: "product",
    value: "3136",
    label: "EBM Karachi (3136)",
  },
  {
    machineCode: "3137",
    machineName: "EBM Hattar",
    type: "product",
    value: "3137",
    label: "EBM Hattar (3137)",
  },
  {
    machineCode: "3122",
    machineName: "Unilever SP",
    type: "product",
    value: "3122",
    label: "Unilever SP (3122)",
  },
  {
    machineCode: "3108",
    machineName: "KE KEDNA",
    type: "product",
    value: "3108",
    label: "Unilever SP (3122)",
  },
  {
    machineCode: "3123",
    machineName: "Artistic Millionaire",
    type: "product",
    value: "3123",
    label: "Artistic Millionaire (3123)",
  },
  {
    machineCode: "3134",
    machineName: "EBM",
    type: "product",
    value: "3134",
    label: "EBM (3134)",
  },
];

export const machineLocations = [
  {
    machineCode: "0002",
    machineName: "Unilever Innovation Lab",
    type: "liquid",
    value: "0002",
    label: "Unilever Innovation Lab",
    location: "https://goo.gl/maps/42MZ7DB5nNciGK2QA",
  },
  {
    machineCode: "3002",
    machineName: "Emaar",
    type: "product",
    value: "3002",
    label: "Emaar",
    location: "https://g.co/kgs/smKxfmM",
  },
  {
    machineCode: "3112",
    machineName: "Dolmen Mall",
    type: "product",
    value: "3112",
    label: "Dolmen Mall",
    location: "https://g.co/kgs/smKxfmM",
  },
  {
    machineCode: "3101",
    machineName: "Tapal IBA Aman CED",
    type: "product",
    value: "3101",
    label: "Tapal IBA Aman CED",
    location: "https://maps.app.goo.gl/37eGKQEwcxMm65k39",
  },
  {
    machineCode: "3125",
    machineName: "JP Coats Karachi",
    type: "product",
    value: "3125",
    label: "JP Coats",
    location: "https://maps.app.goo.gl/gi247Lhbpsv5TMfJ6",
  },
  {
    machineCode: "3126",
    machineName: "Jp Coats Lahore	",
    type: "product",
    value: "3126",
    label: "Jp Coats Lahore	",
    location: "https://maps.app.goo.gl/gi247Lhbpsv5TMfJ6",
  },
  {
    machineCode: "3102",
    machineName: "IVS 3rd floor",
    type: "product",
    value: "3102",
    label: "IVS 3rd floor",
    location: "https://goo.gl/maps/W1bN3u8FWr5Mo41Z6",
  },

  {
    machineCode: "3103",
    machineName: "IVS 3nd floor",
    type: "product",
    value: "3103",
    label: "IVS 3nd floor",
    location: "https://goo.gl/maps/W1bN3u8FWr5Mo41Z6",
  },
  {
    machineCode: "3104",
    machineName: "IVS 2nd floor	",
    type: "product",
    value: "3104",
    label: "IVS 2nd floor	",
    location: "https://goo.gl/maps/shJFVX7UTMZACTicA",
  },
  {
    machineCode: "3105",
    machineName: "KE HO",
    type: "product",
    value: "3105",
    label: "KE HO",
    location: "https://goo.gl/maps/shJFVX7UTMZACTicA",
  },

  {
    machineCode: "3106",
    machineName: "Iba faysal block",
    type: "product",
    value: "3106",
    label: "Iba faysal block",
    location: "https://goo.gl/maps/shJFVX7UTMZACTicA",
  },

  {
    machineCode: "3107",
    machineName: "KE Elander - Healthcare",
    type: "product",
    value: "3107",
    label: "KE Elander - Healthcare",
    location: "https://goo.gl/maps/shJFVX7UTMZACTicA",
  },
  {
    machineCode: "3109",
    machineName: "KE Civic centre",
    type: "product",
    value: "3109",
    label: "KE Civic centre",
    location: "https://goo.gl/maps/shJFVX7UTMZACTicA",
  },
  {
    machineCode: "3110",
    machineName: "IBEX",
    type: "product",
    value: "3110",
    label: "IBEX",
    location: "https://goo.gl/maps/shJFVX7UTMZACTicA",
  },
  {
    machineCode: "3111",
    machineName: "Emaar",
    type: "product",
    value: "3111",
    label: "Emaar",
    location: "https://g.co/kgs/smKxfmM",
  },
  {
    machineCode: "3201",
    machineName: "IBA City Campus",
    type: "product",
    value: "3201",
    label: "IBA City Campus",
    location: "https://goo.gl/maps/shJFVX7UTMZACTicA",
  },
  {
    machineCode: "3202",
    machineName: "AKU Full Pack",
    type: "product",
    value: "3202",
    label: "AKU Full Pack",
    location: "https://goo.gl/maps/shJFVX7UTMZACTicA",
  },
  {
    machineCode: "3203",
    machineName: "IBA Girls Hostel",
    type: "product",
    value: "3203",
    label: "IBA Girls Hostel",
    location: "https://goo.gl/maps/shJFVX7UTMZACTicA",
  },
  {
    machineCode: "4101",
    machineName: "Mian noor",
    type: "liquid",
    value: "4101",
    label: "Mian noor",
    location: "https://g.co/kgs/dkdknd",
  },

  {
    machineCode: "4102",
    machineName: "PCSIR",
    type: "liquid",
    value: "4102",
    label: "PCSIR",
    location: "https://g.co/kgs/dkdknd",
  },
  {
    machineCode: "2101",
    machineName: "Davaam Office",
    type: "liquid",
    value: "2101",
    label: "Davaam Office",
    location: "https://g.co/kgs/dkdknd",
  },

  {
    machineCode: "2201",
    machineName: "Davaam Office",
    type: "liquid",
    value: "4101",
    label: "Davaam Office",
    location: "https://g.co/kgs/dkdknd",
  },
  {
    machineCode: "4001",
    machineName: "Davaam Office",
    type: "liquid",
    value: "4001",
    label: "Davaam Office",
    location: "https://g.co/kgs/dkdknd",
  },
  {
    machineCode: "4002",
    machineName: "Davaam Office",
    type: "liquid",
    value: "4002",
    label: "Davaam Office",
    location: "https://g.co/kgs/dkdknd",
  },
  {
    machineCode: "4103",
    machineName: "Akthar",
    type: "liquid",
    value: "4103",
    label: "Akthar",
    location: "https://g.co/kgs/dkdknd",
  },
  {
    machineCode: "3108",
    machineName: "KE KEDNA",
    type: "product",
    value: "3108",
    label: "KE KEDNA",
    location: "https://g.co/kgs/dkdknd",
  },
  {
    machineCode: "3134",
    machineName: "EBM",
    type: "product",
    value: "3134",
    label: "EBM",
    location: "https://g.co/kgs/smKxfmM",
  },
];

export const LIQUID = "liquid";

export const PRODUCT = "product";

export const COMPANY = "company";

export const calculateTime = (time: number) => {
  const userCreatedAt = time;
  const userCreatedAtGMT = moment(userCreatedAt).tz("Etc/GMT-0");
  const userCreatedAtGMTplus5 = userCreatedAtGMT.clone().add(5, "hours");
  const formattedDate = userCreatedAtGMTplus5.format("llll");
  return formattedDate;
};

const secretKey =
  "6IrQi6OEKps42Y34p9dH0rwqTP59h03P5sFBWk2U4kdsEOHBmibIjtcfxQZVZjTqa1CEMdCPZ4rFdJ50TqweKTYCbEO8HXTbyBfr"; // This is the secret key for encryption

// Function to encrypt an object
export const encryptObject = (object: any) => {
  const stringifiedObject = JSON.stringify(object);
  let encrypted = "";
  for (let i = 0; i < stringifiedObject.length; i++) {
    encrypted += String.fromCharCode(
      stringifiedObject.charCodeAt(i) ^
        secretKey.charCodeAt(i % secretKey.length)
    );
  }
  return btoa(encrypted);
};

// Function to decrypt an object
export const decryptObject = (encryptedString: any) => {
  if (encryptedString) {
    const decrypted = atob(encryptedString);
    let decryptedString = "";
    for (let i = 0; i < decrypted.length; i++) {
      decryptedString += String.fromCharCode(
        decrypted.charCodeAt(i) ^ secretKey.charCodeAt(i % secretKey.length)
      );
    }
    return JSON.parse(decryptedString);
  } else {
    return null;
  }
};

export const NATIONAL_FOODS = "National";

export const IBEX = "Ibex";

export const SUPER_ADMIN = "super admin"

export const MERIT_PACKAGING = "Merit"

export const JP_COATS = "jp"

export const BUTTERFLY = "Butterfly"

export const EBM = "English"
