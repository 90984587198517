import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';
import axios from 'axios';
import { BASE_URL_TWO } from 'Constant/Constant';
import { toast } from 'react-toastify';

type Inputs = {
    employeeId: string;
    password: string;
    balance: number;
    name: string;
};

export default function AddUser({ open, setOpen, getUsers }: { open: boolean; setOpen: (open: boolean) => void; getUsers: any }) {
    //localStorage
    const authData = localStorage.getItem("auth");
    const localStorageDataDecrypted = JSON.parse(authData);

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);


    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setLoading(true);
        try {
            const response = await fetch(`${BASE_URL_TWO}api/ebm`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorageDataDecrypted.encryptedUser

                },
                body: JSON.stringify({
                    employeeId: data.employeeId,
                    pin: data.password,
                    balance: data.balance,
                    name:data.name
                })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const resData = await response.json();
            setLoading(false);
            reset();
            setOpen(false);
            toast.success("User added successfully");
            getUsers();
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    };


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-full overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="grid grid-cols-3 gap-4">
                                        <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                                            Add user
                                        </h1>

                                    </div>

                                    <div className="p-1 w-full">
                                        <label
                                            htmlFor="name"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Name
                                        </label>
                                        <div className="relative mt-2 rounded-md shadow-sm">
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className={`block w-full pl-2 rounded-md border-0 py-1.5 pr-10 ${errors.name ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                                                        : 'text-black ring-black placeholder:text-black focus:ring-black'
                                                    } ring-1 ring-inset focus:ring-2 sm:text-sm sm:leading-6`}
                                                placeholder="John Doe"
                                                {...register('name', { required: true })}
                                            />
                                            {errors.name && (
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon
                                                        className="h-5 w-5 text-red-500"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {errors.name && (
                                            <p className="mt-2 text-sm text-red-600" id="name-error">
                                                Please enter a valid name.
                                            </p>
                                        )}
                                    </div>


                                    <div className="p-1 w-full">
                                        <label htmlFor="employeeId" className="block text-sm font-medium leading-6 text-gray-900">
                                            Employee id
                                        </label>
                                        <div className="relative mt-2 rounded-md shadow-sm">
                                            <input
                                                type="text"
                                                name="employeeId"
                                                id="employeeId"
                                                className={`block w-full pl-2 rounded-md border-0 py-1.5 pr-10 ${errors.employeeId ? 'text-red-900' : 'text-black'
                                                    } ring-1 ring-inset ${errors.employeeId ? 'ring-red-300' : 'ring-black'
                                                    } ${errors.employeeId
                                                        ? 'placeholder:text-red-300'
                                                        : 'placeholder:text-black'
                                                    } focus:ring-2 focus:ring-inset ${errors.employeeId ? 'focus:ring-red-500' : 'focus:ring-black'
                                                    } sm:text-sm sm:leading-6`}
                                                placeholder="1234"
                                                {...register('employeeId', { required: true })}
                                            />
                                            {errors.employeeId && (
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            )}
                                        </div>
                                        {errors.employeeId && (
                                            <p className="mt-2 text-sm text-red-600" id="employeeId-error">
                                                Please enter a valid employee ID
                                            </p>
                                        )}
                                    </div>

                                    <div className="p-1">
                                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                            Password
                                        </label>
                                        <div className="relative mt-2 rounded-md shadow-sm">
                                            <input
                                                type={showPassword ? "text" : "password"} // Show or hide password based on state
                                                name="password"
                                                id="password"
                                                className={`block w-full pl-2 rounded-md border-0 py-1.5 pr-10 ${errors.password ? "text-red-900" : "text-black"} ring-1 ring-inset ${errors.password ? "ring-red-300" : "ring-black"} ${errors.password ? "placeholder:text-red-300" : "placeholder:text-black"}  focus:ring-2 focus:ring-inset ${errors.password ? "focus:ring-red-500" : "focus:ring-black"} sm:text-sm sm:leading-6`}
                                                placeholder="*******"
                                                aria-invalid="true"
                                                aria-describedby="password-error"
                                                {...register("password", {
                                                    required: true,
                                                })}
                                            />
                                            {errors.password &&
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            }
                                            {/* Checkbox to toggle password visibility */}
                                            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                                <input
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                                                    onChange={() => setShowPassword(!showPassword)} // Toggle state when checkbox is clicked
                                                />
                                            </div>
                                        </div>
                                        {
                                            errors.password &&
                                            <p className="mt-2 text-sm text-red-600" id="email-error">
                                                Please enter a password
                                            </p>
                                        }
                                    </div>

                                    <div className="p-1">
                                        <label htmlFor="balance" className="block text-sm font-medium leading-6 text-gray-900">
                                            Balance
                                        </label>
                                        <div className="relative mt-2 rounded-md shadow-sm">
                                            <input
                                                type="text"
                                                name="balance"
                                                id="balance"
                                                className={`block w-full pl-2 rounded-md border-0 py-1.5 pr-10 ${errors.balance ? 'text-red-900' : 'text-black'
                                                    } ring-1 ring-inset ${errors.balance ? 'ring-red-300' : 'ring-black'
                                                    } ${errors.balance
                                                        ? 'placeholder:text-red-300'
                                                        : 'placeholder:text-black'
                                                    } focus:ring-2 focus:ring-inset ${errors.balance ? 'focus:ring-red-500' : 'focus:ring-black'
                                                    } sm:text-sm sm:leading-6`}
                                                placeholder="80 - 1000"
                                                {...register('balance', { required: true })}
                                            />
                                            {errors.balance && (
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            )}
                                        </div>
                                        {errors.balance && (
                                            <p className="mt-2 text-sm text-red-600" id="balance-error">
                                                Please enter a valid balance
                                            </p>
                                        )}
                                    </div>

                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        {
                                            loading ?
                                                <button

                                                    className="inline-flex cursor-pointer w-full justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 sm:ml-3 sm:w-auto"
                                                >Loading...</button> :
                                                <input
                                                    type="submit"
                                                    className="inline-flex cursor-pointer w-full justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 sm:ml-3 sm:w-auto"
                                                    value="Add User"
                                                />
                                        }

                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
