// Admin Imports
import MainDashboard from "views/admin/Dashboard";
import Machines from "views/admin/Machines";
import MachineDetails from "views/admin/MachineDetails/MachineDetails";
import Profile from "views/admin/ProductDetails";
import DataTables from "views/admin/Locations";

// Auth Imports
import { GiVendingMachine } from "react-icons/gi";
import { RiUserStarFill } from "react-icons/ri";
import { HiUsers } from "react-icons/hi";
import { IoNotifications } from "react-icons/io5";

// Icon Imports
import {
  MdHome,
  MdLocationPin,
  MdPerson,
} from "react-icons/md";
import { SiMoneygram } from "react-icons/si";
import { RiHandCoinFill } from "react-icons/ri";
import { RiFeedbackFill } from "react-icons/ri";
import { RiAdminFill } from "react-icons/ri";

import Topup from "views/admin/Topup";
import Users from "views/admin/Users";
import LocationDetails from "views/admin/Locations/LocationDetails/LocationDetails";
import PrivacyPolicy from "views/auth/PrivacyPolicy";
import CorporateClients from "views/admin/CorportaeClients";
import CompanyInfo from "views/auth/CompanyInfo";
import SendNotifications from "views/admin/SendNotifications";
import PointsShare from "views/admin/PointsShare";
import CreateRoles from "views/admin/CreateRoles";
import NationalFoods from "views/admin/NationalFoodsUsersData/NationalFoods";
import MeritPackaging from "views/admin/MeritPackaging";
import TopupNFL from "views/admin/TopupNFL";
import JpCoats from "views/admin/JpCoats";
import Ebm from "views/admin/Ebm";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "home",
    admin: true,
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Create Roles",
    layout: "/admin",
    path: "create-roles",
    admin: true,
    icon: <RiAdminFill className="h-6 w-6" />,
    component: <CreateRoles />,
    secondary: true,
  },
  {
    name: "Machines",
    layout: "/admin",
    path: "machines",
    admin: true,
    icon: <GiVendingMachine className="h-6 w-6" />,
    component: <Machines />,
    secondary: true,
  },
  {
    name: "Machine Details",
    layout: "/admin",
    path: "machine-details/:id/:type",
    icon: <GiVendingMachine className="h-6 w-6" />,
    component: <MachineDetails />,
    show: false,
    admin: true,
  },
  {
    name: "Points Share",
    layout: "/admin",
    path: "points-share",
    admin: true,
    icon: <RiHandCoinFill className="h-6 w-6" />,
    component: <PointsShare />,
    // secondary: true,
  },
  {
    name: "Locations",
    layout: "/admin",
    path: "locations",
    icon: <MdLocationPin className="h-6 w-6" />,
    component: <DataTables />,
    admin: true,
  },
  {
    name: "Location Details",
    layout: "/admin",
    path: "location-details/:id",
    icon: <GiVendingMachine className="h-6 w-6" />,
    component: <LocationDetails />,
    show: false,
    admin: true,
  },
  {
    name: "Product Details",
    layout: "/admin",
    path: "product-details",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
    admin: true,
  },
  {
    name: "Topup",
    layout: "/admin",
    path: "topup",
    icon: <SiMoneygram className="h-6 w-6" />,
    component: <Topup />,
    admin: false,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "users",
    icon: <HiUsers className="h-6 w-6" />,
    component: <Users />,
    admin: true,
  },
  {
    name: "Privacy Policy",
    layout: "/auth",
    path: "privacy-policy",
    icon: <RiFeedbackFill className="h-6 w-6" />,
    component: <PrivacyPolicy />,
    admin: true,
  },
  {
    name: "Company info",
    layout: "/auth",
    path: "company-info",
    icon: <RiFeedbackFill className="h-6 w-6" />,
    component: <CompanyInfo />,
    admin: true,
  },
  {
    name: "Corporate Clients",
    layout: "/admin",
    path: "clients",
    icon: <RiUserStarFill className="h-6 w-6" />,
    component: <CorporateClients />,
    admin: true,
  },
  {
    name: "Send Notifications",
    layout: "/admin",
    path: "send-notifications",
    icon: <IoNotifications className="h-6 w-6" />,
    component: <SendNotifications />,
    admin: true,
  },
  {
    name: "Users National Foods",
    layout: "/admin",
    path: "user-national-foods",
    icon: <HiUsers className="h-6 w-6" />,
    component: <NationalFoods />,
    admin: true,
  },
  {
    name: "Topup Nfl",
    layout: "/admin",
    path: "topup-nfl",
    icon: <SiMoneygram className="h-6 w-6" />,
    component: <TopupNFL />,
    admin: true,
  },
  {
    name: "Merit Packaging Users",
    layout: "/admin",
    path: "merit-packaging-users",
    icon: <HiUsers className="h-6 w-6" />,
    component: <MeritPackaging />,
    admin: true,
  },
  {
    name: "JP coats",
    layout: "/admin",
    path: "jp-coats",
    icon: <HiUsers className="h-6 w-6" />,
    component: <JpCoats />,
    admin: true,
  },
  {
    name: "Ebm",
    layout: "/admin",
    path: "user-ebm",
    icon: <HiUsers className="h-6 w-6" />,
    component: <Ebm />,
    admin: true,
  },
];
export default routes;
