import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { BASE_URL_TWO } from 'Constant/Constant';

type FormValues = {
    brandIdOne: number;
    batchNumberOne: number;
    expiryDateOne: string;
    quantityOne: number;

    brandIdTwo: number;
    batchNumberTwo: number;
    expiryDateTwo: string;
    quantityTwo: number;

    brandIdThree: number;
    batchNumberThree: number;
    expiryDateThree: string;
    quantityThree: number;

    brandIdFour: number;
    batchNumberFour: number;
    expiryDateFour: string;
    quantityFour: number;
};


export default function AddStockModal(props: { open: boolean, setOpen: React.Dispatch<React.SetStateAction<any>>, getRealTimeStocks:any, getStocks:any }) {
    const { open, setOpen, getRealTimeStocks, getStocks } = props;

    const cancelButtonRef = useRef(null);

    const [loading, setLoading] = useState(false);

    // Initialize react-hook-form
    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();

    // Handle form submission
    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        setLoading(true)

        const stockData = [
            {
                brand_id: data.brandIdOne,
                batch_number: data.batchNumberOne,
                expiry_date: data.expiryDateOne,
                quantity: data.quantityOne,
                epoch_time: Date.now()
            },
            {
                brand_id: data.brandIdTwo,
                batch_number: data.batchNumberTwo,
                expiry_date: data.expiryDateTwo,
                quantity: data.quantityTwo,
                epoch_time: Date.now()
            },
            {
                brand_id: data.brandIdThree,
                batch_number: data.batchNumberThree,
                expiry_date: data.expiryDateThree,
                quantity: data.quantityThree,
                epoch_time: Date.now()
            },
            {
                brand_id: data.brandIdFour,
                batch_number: data.batchNumberFour,
                expiry_date: data.expiryDateFour,
                quantity: data.quantityFour,
                epoch_time: Date.now()
            },
        ]

        try {
            const response = await fetch(`${BASE_URL_TWO}api/stocks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ brandDetails: stockData }),
            });

            if (response.ok) {
                setLoading(false);
                getRealTimeStocks();
                getStocks();
                setOpen(false);
            } else {
                console.error('Failed to add stock');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto mt-10">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden dark:!bg-gray-900 bg-white rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                                <div className='p-4'>
                                    <h1 className='text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>Add Stock</h1>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className='py-3'>


                                            <div className="flex justify-between py-2">
                                                <div>
                                                    <label>Brand id</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Brand id"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.brandIdOne ? 'border-red-500' : ''}`}
                                                        {...register('brandIdOne', { required: 'Brand id is required' })}
                                                    />
                                                    {errors.brandIdOne && <p className="text-red-500 text-sm">{errors.brandIdOne.message}</p>}
                                                </div>
                                                <div>
                                                    <label>Batch Number</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Batch Number"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.batchNumberOne ? 'border-red-500' : ''}`}
                                                        {...register('batchNumberOne', { required: 'Batch number is required' })}
                                                    />
                                                    {errors.batchNumberOne && <p className="text-red-500 text-sm">{errors.batchNumberOne.message}</p>}
                                                </div>
                                                <div>
                                                    <label>Expiry Date</label>
                                                    <input
                                                        type="date"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.expiryDateOne ? 'border-red-500' : ''}`}
                                                        {...register('expiryDateOne', { required: 'Expiry date is required' })}
                                                    />
                                                    {errors.expiryDateOne && <p className="text-red-500 text-sm">{errors.expiryDateOne.message}</p>}
                                                </div>
                                                <div>
                                                    <label>Quantity</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Quantity"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.quantityOne ? 'border-red-500' : ''}`}
                                                        {...register('quantityOne', {
                                                            required: 'Quantity is required',
                                                            valueAsNumber: true,
                                                            min: { value: 1, message: 'Quantity must be at least 1' },
                                                        })}
                                                    />
                                                    {errors.quantityOne && <p className="text-red-500 text-sm">{errors.quantityOne.message}</p>}
                                                </div>
                                            </div>



                                            <div className="flex justify-between py-2">
                                                <div>
                                                    <label>Brand id</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Brand id"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.brandIdTwo ? 'border-red-500' : ''}`}
                                                        {...register('brandIdTwo', { required: 'Brand id is required' })}
                                                    />
                                                    {errors.brandIdTwo && <p className="text-red-500 text-sm">{errors.brandIdTwo.message}</p>}
                                                </div>
                                                <div>
                                                    <label>Batch Number</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Batch Number"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.batchNumberTwo ? 'border-red-500' : ''}`}
                                                        {...register('batchNumberTwo', { required: 'Batch number is required' })}
                                                    />
                                                    {errors.batchNumberTwo && <p className="text-red-500 text-sm">{errors.batchNumberTwo.message}</p>}
                                                </div>
                                                <div>
                                                    <label>Expiry Date</label>
                                                    <input
                                                        type="date"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.expiryDateTwo ? 'border-red-500' : ''}`}
                                                        {...register('expiryDateTwo', { required: 'Expiry date is required' })}
                                                    />
                                                    {errors.expiryDateTwo && <p className="text-red-500 text-sm">{errors.expiryDateTwo.message}</p>}
                                                </div>
                                                <div>
                                                    <label>Quantity</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Quantity"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.quantityTwo ? 'border-red-500' : ''}`}
                                                        {...register('quantityTwo', {
                                                            required: 'Quantity is required',
                                                            valueAsNumber: true,
                                                            min: { value: 1, message: 'Quantity must be at least 1' },
                                                        })}
                                                    />
                                                    {errors.quantityTwo && <p className="text-red-500 text-sm">{errors.quantityTwo.message}</p>}
                                                </div>
                                            </div>


                                            <div className="flex justify-between py-2">
                                                <div>
                                                    <label>Brand id</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Brand id"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.brandIdThree ? 'border-red-500' : ''}`}
                                                        {...register('brandIdThree', { required: 'Brand id is required' })}
                                                    />
                                                    {errors.brandIdThree && <p className="text-red-500 text-sm">{errors.brandIdThree.message}</p>}
                                                </div>
                                                <div>
                                                    <label>Batch Number</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Batch Number"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.batchNumberThree ? 'border-red-500' : ''}`}
                                                        {...register('batchNumberThree', { required: 'Batch number is required' })}
                                                    />
                                                    {errors.batchNumberThree && <p className="text-red-500 text-sm">{errors.batchNumberThree.message}</p>}
                                                </div>
                                                <div>
                                                    <label>Expiry Date</label>
                                                    <input
                                                        type="date"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.expiryDateThree ? 'border-red-500' : ''}`}
                                                        {...register('expiryDateThree', { required: 'Expiry date is required' })}
                                                    />
                                                    {errors.expiryDateThree && <p className="text-red-500 text-sm">{errors.expiryDateThree.message}</p>}
                                                </div>
                                                <div>
                                                    <label>Quantity</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Quantity"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.quantityThree ? 'border-red-500' : ''}`}
                                                        {...register('quantityThree', {
                                                            required: 'Quantity is required',
                                                            valueAsNumber: true,
                                                            min: { value: 1, message: 'Quantity must be at least 1' },
                                                        })}
                                                    />
                                                    {errors.quantityThree && <p className="text-red-500 text-sm">{errors.quantityThree.message}</p>}
                                                </div>
                                            </div>


                                            <div className="flex justify-between py-2">
                                                <div>
                                                    <label>Brand id</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Brand id"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.brandIdFour ? 'border-red-500' : ''}`}
                                                        {...register('brandIdFour', { required: 'Brand id is required' })}
                                                    />
                                                    {errors.brandIdFour && <p className="text-red-500 text-sm">{errors.brandIdFour.message}</p>}
                                                </div>
                                                <div>
                                                    <label>Batch Number</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Batch Number"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.batchNumberFour ? 'border-red-500' : ''}`}
                                                        {...register('batchNumberFour', { required: 'Batch number is required' })}
                                                    />
                                                    {errors.batchNumberFour && <p className="text-red-500 text-sm">{errors.batchNumberFour.message}</p>}
                                                </div>
                                                <div>
                                                    <label>Expiry Date</label>
                                                    <input
                                                        type="date"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.expiryDateFour ? 'border-red-500' : ''}`}
                                                        {...register('expiryDateFour', { required: 'Expiry date is required' })}
                                                    />
                                                    {errors.expiryDateFour && <p className="text-red-500 text-sm">{errors.expiryDateFour.message}</p>}
                                                </div>
                                                <div>
                                                    <label>Quantity</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Quantity"
                                                        className={`block w-full rounded-md px-3 py-1.5 text-base outline outline-1 outline-gray-300 focus:outline-teal-600 sm:text-sm/6 ${errors.quantityFour ? 'border-red-500' : ''}`}
                                                        {...register('quantityFour', {
                                                            required: 'Quantity is required',
                                                            valueAsNumber: true,
                                                            min: { value: 1, message: 'Quantity must be at least 1' },
                                                        })}
                                                    />
                                                    {errors.quantityFour && <p className="text-red-500 text-sm">{errors.quantityFour.message}</p>}
                                                </div>
                                            </div>

                                        </div>

                                        <div className='flex justify-end w-full gap-2'>
                                            <button type='button' onClick={() => setOpen(false)} className='rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'>Cancel</button>

                                            {
                                                loading ?
                                                    <button type='button' className='rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'>Loading...</button>
                                                    :
                                                    <input type='submit' value="Add Stock" className='cursor-pointer rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600' />

                                            }
                                        </div>
                                    </form>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}



